import Participants from '../../components/Participants/Participants'

function MarketPatisipantsPage() {
    return(
        <>
          <Participants/>
        </>
    )
}

export default MarketPatisipantsPage